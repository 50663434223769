import React, { useEffect, useState, useRef } from 'react'
import dynamic from 'next/dynamic'

import type { StandardCart, StandardLineItem } from '@/types/ShopFront/CheckoutStandards'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { error, log } from '@/services/Log'

const CartPreview = dynamic(import('./CartPreviewExpandedContent'))

interface PreviewContentProps {
  cart: StandardCart | null,
  lineItems: StandardLineItem[],
  onClose: () => void,
  onUpdateCart: (cart: StandardCart | null) => void,
  removeCoupon: React.MouseEventHandler<HTMLButtonElement>,
  visible: boolean,
}

const PreviewContent = ({
  cart,
  lineItems,
  onClose,
  onUpdateCart,
  removeCoupon,
  visible,
}: PreviewContentProps) => {
  const mainDivRef = useRef<HTMLHeadingElement>(null)
  const [qtyErrorId, setQtyErrorId] = useState<string>('')
  const [couponError, setCouponError] = useState<string>('')

  useEffect(() => {
    function handleClickOutside(event: Event) {
      const clickOutsideExpandedMenu = (
        mainDivRef?.current
          ? !(mainDivRef?.current?.contains(event.target as Node))
          : true
      )
      if (clickOutsideExpandedMenu) {
        event.stopPropagation()
        onClose()
      }
    }
    // Bind the event listener
    if (visible) {
      document.addEventListener('click', handleClickOutside)
      document.addEventListener('touchend', handleClickOutside)
    }
    return () => { // Unbind the event listener on clean up
      try {
        document.removeEventListener('click', handleClickOutside)
        document.removeEventListener('touchend', handleClickOutside)
      } catch {
        // Do nothing
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDivRef, visible])

  type idAndQuantity = { id:string, quantity:number }
  const onUpdate = async ({ id, quantity }: idAndQuantity) => {
    const [
      { default: cartUpdateQuantity },
      { checkIsExtendItem },
      { productUpdateGA },
    ] = await allPromisesWithRetries(() => [
      import('@/services/Cart/cartUpdateQuantity'),
      import('@/helpers/checkIsExtendItem'),
      import('@/helpers/common'),
    ])
    const event = 'addToCart'
    if (!cart) {
      return
    }
    setQtyErrorId('')
    const cartItemToExecuteAction = cart.lineItems.find((item) => item.id === id)
    const productId = cartItemToExecuteAction?.productId
    const variantId = cartItemToExecuteAction?.variantId

    const lineItem = lineItems.find((item) => item.id === id)

    if (lineItem && checkIsExtendItem(lineItem)) {
      return
    }

    const response = await cartUpdateQuantity({
      cartId: cart?.id,
      itemId: id,
      quantity,
      productId,
      variantId,
    })
    log('CartPreview: Cart updated', { response })
    const cartItemAfterActionExecuted = response?.newCart?.lineItems.find((item) => item.id === id)
    log('cartItemAfterActionExecuted', { cartItemAfterActionExecuted, lineItem, cartItemToExecuteAction })
    if (response.newCart) {
      onUpdateCart(response.newCart)
    }
    if (response.success && cartItemAfterActionExecuted) {
      productUpdateGA(cartItemAfterActionExecuted, event, response.newCart?.total || 0)
    }
    if (!response.success || (cartItemAfterActionExecuted?.quantity !== quantity)) {
      log('CartPreview: Cart update failed', {
        response, cartItemAfterActionExecuted, lineItem, cartItemToExecuteAction, quantity,
      })
      setQtyErrorId(id)
    }
  }

  const sendEventToGA = async (currentCartItem : StandardLineItem) => {
    const [
      { getCart },
      { productGA },
    ] = await allPromisesWithRetries(() => [
      import('@/services/Cart/getCart'),
      import('@/helpers/GA4Events'),
    ])
    const initialCart = await getCart()
    productGA({
      name: currentCartItem.name,
      sku: currentCartItem.sku,
      price: `${currentCartItem.subtotalPrice || currentCartItem.subtotalPrice}`,
      salePrice: currentCartItem.salePrice,
      variantId: currentCartItem.variantId,
      categories: [],
      event: 'deleteFromCart',
      brand: currentCartItem.brand,
      quantity: 1,
      cartTotal: initialCart?.total || 0,
    })
  }

  const onDelete = async ({ id, quantity }: idAndQuantity) => {
    const [{ default: cartDeleteItem }] = await allPromisesWithRetries(() => [import('@/services/Cart/cartDeleteItem')])
    if (!cart) {
      return
    }
    setQtyErrorId('')
    const cartItemToExecuteAction = cart.lineItems.find((item) => item.id === id)
    if (!cartItemToExecuteAction) {
      setQtyErrorId(id)
      return
    }
    sendEventToGA(cartItemToExecuteAction).catch(error)
    const productId = cartItemToExecuteAction?.productId
    const variantId = cartItemToExecuteAction?.variantId

    const response = await cartDeleteItem({
      cartId: cart?.id,
      itemId: id,
      quantity,
      productId,
      variantId,
    })
    if (response.success) {
      onUpdateCart(response.newCart)
    } else {
      setQtyErrorId(id)
    }
  }

  const onCouponSubmit = async (couponCode: string) => {
    setCouponError('')
    const [
      { default: applyCoupon },
      { getCart },
    ] = await allPromisesWithRetries(() => [
      import('@/services/Cart/applyCoupon'),
      import('@/services/Cart/getCart'),
    ])
    const data = await applyCoupon(couponCode)
    if (data.status === 'success') {
      onUpdateCart(await getCart())
    } else {
      setCouponError(data?.errors?.[0])
    }
  }
  return (!visible || !cart)
    ? null
    : (
      <>
        <div id="backOfcartPreview" className="md:hidden fixed top-0 left-0 bg-backdrop-light bg-opacity-80 w-screen h-screen" />
        <div
          className="hidden md:block w-6 h-1 absolute top-8 right-0"
          style={{
            backgroundColor: '#3B5F58',
          }}
        />
        <div
          ref={mainDivRef}
          className="cartPreview z-500"
          data-dropdown-content="true"
        >
          <CartPreview
            checkoutLink="/checkout/shipping"
            id="cartPreview"
            label="Cart Preview"
            lineItems={lineItems}
            onQtyChange={() => { }}
            onQtyDelete={onDelete}
            onQtyUpdate={onUpdate}
            onUpdateCart={onUpdateCart}
            onClose={onClose}
            qtyError="Sorry we do not have enough stock for the quantity you have selected."
            qtyErrorId={qtyErrorId}
            onCouponSubmit={onCouponSubmit}
            onRemoveCoupon={removeCoupon}
            couponError={couponError}
            clearCouponError={() => setCouponError('')}
            cart={cart}
          />
        </div>
      </>
    )
}

export default PreviewContent
